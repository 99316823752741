import React from "react";
import { variantPages } from "../animations/pageTransition";
import { motion } from "framer-motion";
import MetaTag from "../components/metaTag";
import { infoSEO } from "../components/data/seo";
import {
  container,
  preview,
  title,
  question,
  block,
  vector,
} from "../styles/pages/info.module.scss";

function Info() {
  return (
    <>
      <MetaTag data={infoSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
        className={container}
      >
        <div className={preview}>
          <h2 className={title}>Полезная информация</h2>
          <p>Часто задаваемые вопросы</p>
          <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
        </div>
        <div className={question}>
          <div className={block}>
            <h2>От чего зависят расценки на уборку?</h2>
            <p>
              Цена уборки зависит от следующих компонентов:
              <br />
              Площадь квартиры/дома
              <br />
              Вид услуги, который вы заказали
              <br />
              Ваши индивидуальные пожелания.
            </p>
          </div>
          <div className={block}>
            <h2>Можно ли заключить договор?</h2>
            <p>
              Да, вы можете заключить с нами договор и мы предоставим вам
              скидку, как постоянному клиенту. Все подробности вы можете узнать
              по телефону или e-mail.
            </p>
          </div>
          <div className={block}>
            <h2>Что не входит в стоимость уборки?</h2>
            <p>
              В стоимость уборки не входят потолки, жалюзи и стены. Также мы не
              отодвигаем тяжелую мебель.
            </p>
          </div>
          <div className={block}>
            <h2>
              Если ваш сотрудник сломал что-нибудь, кто несет ответственность?
            </h2>
            <p>
              В нашей компании работают профессионалы с многолетним стажем.
              Можем уверить вас, что подобных ситуаций не возникнет. Если же
              такое все-таки случится – мы гарантируем возмещение ущерба,
              причиненного нашим сотрудником.
            </p>
          </div>
          <div className={block}>
            <h2>Что делать, если после уборки пропали ценные вещи?</h2>
            <p>
              Можете не беспокоиться! Перед приемом на работу все сотрудники
              проходят проверку службой безопасности и дорожат своей работой.
              Если все же подобная ситуация случится и вы будете сомневаться в
              честности нашего сотрудника – свяжитесь с нами и мы примем
              необходимые меры.
            </p>
          </div>
        </div>
      </motion.section>
    </>
  );
}

export default Info;
